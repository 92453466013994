import { Json } from 'app/services/database.types';
import { Injectable } from "@angular/core";
import { FuseConfigService } from "@fuse/services/config";
import { State, Selector, Action, StateContext, NgxsOnInit } from "@ngxs/store";
import { Api } from "app/services/api.service";

export type Context = { id: string, name: string };

export namespace App {
  export class SetContext {
    static readonly type = '[Settings] SetContext';
    constructor(public payload: { contextId: string, role: string, customization: Json }) { }
  }

  export class SetLanguage {
    static readonly type = '[Settings] SetLanguage';
    constructor(public payload: { language: string }) { }
  }

  export class ToggleScheme {
    static readonly type = '[Settings] ToggleScheme';
  }

  export class FetchContexts {
    static readonly type = '[Settings] FetchContexts';
  }
}

export interface AppStateModel {
  contextId: string | null;
  contextRole: string | null;
  contexts: { id: string, name: string }[];
  darkMode: boolean;
  language: string;
  customization: Json;
}

@State<AppStateModel>({
  name: 'app',
  defaults: {
    contextId: null,
    contextRole: null,
    contexts: [],
    darkMode: false,
    language: 'en',
    customization: {},
  }
})
@Injectable()
export class AppState implements NgxsOnInit {
  constructor(
    private api: Api,
    private fuseConfigService: FuseConfigService,
  ) {
  }
  ngxsOnInit({ getState }: StateContext<AppStateModel>): void {
    const currentlyDarkMode = getState().darkMode
    this.fuseConfigService.config = { scheme: currentlyDarkMode ? 'dark' : 'light' };
  }

  @Selector()
  static contextId(state: AppStateModel): string | null {
    return state.contextId;
  }

  @Selector()
  static contextRole(state: AppStateModel): string | null {
    return state.contextRole;
  }

  @Selector()
  static contexts(state: AppStateModel): Context[] {
    return state.contexts;
  }

  @Selector()
  static darkMode(state: AppStateModel): boolean {
    return state.darkMode;
  }

  @Selector()
  static language(state: AppStateModel): string {
    return state.language;
  }

  @Selector()
  static customization(state: AppStateModel): Json | null {
    return state.customization;
  }

  @Action([App.SetContext])
  setContext({ patchState, dispatch, getState, setState }: StateContext<AppStateModel>, action: App.SetContext) {
    const { contextId, role, customization } = action.payload;
    patchState({ contextId, contextRole: role, customization });
  }

  @Action([App.ToggleScheme])
  toggleScheme({ patchState, dispatch, getState, setState }: StateContext<AppStateModel>, action: App.ToggleScheme) {
    const currentlyDarkMode = getState().darkMode

    patchState({ darkMode: !currentlyDarkMode });

    this.fuseConfigService.config = { scheme: currentlyDarkMode ? 'light' : 'dark' };
  }

  @Action([App.SetLanguage])
  signUp({ patchState, dispatch, getState, setState }: StateContext<AppStateModel>, action: App.SetLanguage) {
    const { language } = action.payload;
    patchState({ language });
  }

  @Action([App.FetchContexts])
  async fetchContexts({ patchState, dispatch, getState, setState }: StateContext<AppStateModel>, action: App.ToggleScheme) {
    const { data: contexts, error } = await this.api.supabase
      .from('contexts')
      .select('id, name');

    patchState({ contexts });
  }
}
